import React from 'react';
import logo from "../../logo.png";

const landing_nav = () => (
    <section id="nav">
        <header>
            <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4">
                <a href="/" className="d-flex align-items-center link-body-emphasis text-decoration-none ml-3">
                    <img className='mx-2' src={logo} width="35" height="35" viewBox="0 0 118 94" alt="Logo"/>
                    <span className="fs-4 ml-2">BookBanter</span>
                </a>

                <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto mr-3">
                    <p className="me-3 py-2 my-2"><a className='link-body-emphasis text-decoration-none' href="#about">About &nbsp;</a>|</p>
                    <p className="me-3 py-2 my-2"><a className='link-body-emphasis text-decoration-none' href="#pricing">Pricing &nbsp;</a>|</p>
                    <p className="me-3 py-2 my-2"><a className='link-body-emphasis text-decoration-none' href="#app">App &nbsp;</a>|</p>
                    <p className="me-3 py-2 my-2"><a className='link-body-emphasis text-decoration-none' href="#faq">F&Q &nbsp;</a></p>

                </nav>
            </div>
        </header>
    </section>
);

export default landing_nav;