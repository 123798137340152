import React from 'react';

const LandingFaq = () => (
    <section id="faq" className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5" style={{height: "100vh"}}>
        <div className="col">
            <h1>Frequently asked questions</h1>
        </div>
    </section>
);

export default LandingFaq;