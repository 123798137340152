import React, { useEffect, useState } from 'react';
import { get_query } from '../components/firebase.js';
import { useLocation } from 'react-router-dom'
import { HeartFill, Heart } from "react-bootstrap-icons";
import StarRating from '../components/StarRating';
import GoBack from '../components/GoBack.js';

function BookDetails() {
    const location = useLocation()
    const book = location.state

    const [reviews, setReviews] = useState([]);
    const [users, setUsers] = useState([]);
    const [uids, setUids] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchReviews() {
          const review_data = await get_query('review', ['book', '==', book.isbn]);
          setReviews(review_data);
        };
        if(reviews.length < 1){
          fetchReviews();
        };
    }, [reviews, book.isbn]);

    useEffect(() => {
        if(reviews.length > 0){
          let arr = [];
          reviews.forEach((review) => {if(!arr.includes(review.user)){arr.push(review.user)}});
          setUids(arr);
        };
    }, [reviews]);
    
    useEffect(() => {
        async function fetchUsers() {
            const user_data = await get_query('users', ['uid', 'in', uids])
            setUsers(user_data);
        };

        if(uids.length > 0){
            fetchUsers();
        };
    }, [reviews, uids]);
    
    useEffect(() => {
        if((users.length > 0) && (reviews.length > 0)){
          const res = [];
          reviews.forEach((review) => {
            users.forEach((user) => {
              if(review.user === user.uid){
                    res.push({
                        ...user,
                        ...review
                    });    
              };
            });
          });
          res.sort(function(x, y){return y.updated - x.updated});
          setData(res);
        };
    }, [users, reviews]);

    return (
        <div className="book-details container">
            <GoBack/>
            <hr/>
            <h3>{book.title}</h3>
            <p>Release Date: {book.release}</p>
            <p>Genre: {book.genre}</p>
            {book.authors.lenght === 1 ? (<p>Author: {book.authors[0]}</p>) : (<p>Authors: {book.authors}</p>)}
            <div className='d-flex align-items-start mt-2'>
                <img className='mr-3' src={book.thumbnail} alt={book.title} />
                <p>{book.description}</p>
            </div>
            <hr/> 
            {data.map((review, index) => (
                <div key={review.id}>
                    {review.visibility ? (
                    <div key={index}>
                        <p>{review.name}</p>
                        <div className='d-flex align-items-start'>
                            <StarRating rating={review.stars}/>
                        </div>
                        <p className='mt-3'>{review.review}</p>
                        <div className='d-flex align-itmes-start'>
                            <p className='mr-2'>5</p>
                            <div className='mt-1' onClick={() => {review.like = !review.like}}>
                                {review.like ? (
                                <HeartFill color='red' size={20}/>) : 
                                (<Heart color='red' size={20}/>
                                )}
                            </div>
                        </div>
                        <hr/>
                    </div>
                    ) : ""}
                </div>
            ))} 
        </div>
    );
};

export default BookDetails;
