import React from 'react';
import { Facebook, Twitter, Instagram } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import "../../pages/css/footer.css"

const LandingFooter = () => {
    const navigate = useNavigate();

    return(
        <section id="footer">
            <footer className="bg-dark text-white py-4 text-decoration-none m-0 p-0">
                <div className="container text-center mb-5">
                    <div className="row">
                    <div className="col-md-4 mb-3 mb-md-0">
                        <h5>For users</h5>
                        <a href="/" className="text-white me-2">sign in</a><br/>
                        <a href="/" className="text-white me-2">sign up</a><br/>
                        <a href="/" className="text-white me-2">reset password</a><br/>
                        <button className='btn btn-transparent m-0 p-0' onClick={() => navigate("/login")}>
                            <p className="text-white">sign in</p>
                        </button><br/>
                        <button className='btn btn-transparent m-0 p-0' onClick={() => navigate("/signup")}>
                            <p className="text-white">sign up</p>
                        </button><br/>
                        <button className='btn btn-transparent m-0 p-0' onClick={() => navigate("/reset")}>
                            <p className="text-white">reset password</p>
                        </button><br/>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                        <h5>Legal</h5>
                        <button className='btn btn-transparent m-0 p-0' onClick={() => navigate("/privacy-policy")}>
                            <p className="text-white">privacy policy</p>
                        </button><br/>
                        <button className='btn btn-transparent m-0 p-0' onClick={() => navigate("/terms&conditions")}>
                            <p className="text-white">terms and conditions</p>
                        </button><br/>
                        <button className='btn btn-transparent m-0 p-0' onClick={() => navigate("/imprint")}>
                            <p className="text-white">imprint</p>
                        </button><br/>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                        <h5>Follow Us</h5>
                        <a href="/" className="text-white me-2"><Facebook />Facebook</a><br/>
                        <a href="/" className="text-white me-2"><Twitter />Twitter</a><br/>
                        <a href="/" className="text-white"><Instagram />Instagram</a><br/>
                    </div>
                    </div>
                    <div className="mt-3">
                    <p className="mb-5">&copy; 2024 BookBanter. All rights reserved.</p>
                    </div>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>
            </footer>
        </section>
    );
};

export default LandingFooter;