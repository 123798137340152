import React, { useEffect, useState } from 'react';
import ReviewForm from '../components/ReviewForm.js';
import { get_query, insert, auth } from '../components/firebase.js';

function Addreview() {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const [book_id, setBookId] = useState("");
    // const [user_id, setUserId] = useState("");//("fkFfP8X77mMZEhihcBRc49x24HB3");
    // const uid = GetUserId();

    const handleSearch = async () => {
        try {
            const response = await fetch(`https://www.googleapis.com/books/v1/volumes?q=${query}&key=AIzaSyDfpTRzvu1HfssY-yesH8HclvT0PRi0r44`);
            const data = await response.json();
            setResults(data.items);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSearch();
    };

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const handleBookSelect = (book) => {
        setSelectedBook(book);
    };

    useEffect(() => {
        async function checkBookExists(){
            const data = await get_query('books', ['isbn', '==', selectedBook.volumeInfo.industryIdentifiers[0].identifier]);
            console.log("Data", data);
            if (data.length === 0){
                const book = {
                    isbn: selectedBook.volumeInfo.industryIdentifiers[0].identifier,
                    title: selectedBook.volumeInfo.title,
                    authors: selectedBook.volumeInfo.authors || [],
                    description: selectedBook.volumeInfo.description || '',
                    release: selectedBook.volumeInfo.publishedDate || '',
                    genre: selectedBook.volumeInfo.categories || [],
                    thumbnail: selectedBook.volumeInfo.imageLinks.thumbnail || '',
                    language: selectedBook.volumeInfo.language || '',
                    series: selectedBook.volumeInfo.seriesInfo ? selectedBook.volumeInfo.seriesInfo.series : '',
                }
                insert('books', book);
            };
            setBookId(selectedBook.volumeInfo.industryIdentifiers[0].identifier);
            console.log("Set book id");
            // setUserId("fkFfP8X77mMZEhihcBRc49x24HB3");
            // console.log("Set User id");
        };

        if(selectedBook !== null && book_id === ""){
            checkBookExists();
        };
    }, [selectedBook, book_id]);

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    {book_id === "" ? (
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for books..."
                                        value={query}
                                        onChange={handleChange}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="submit">
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {results.length > 0 && (
                                <div>
                                    <h2>Search Results</h2>
                                    <ul className="list-group">
                                        {results.map((item) => (
                                            <li
                                                key={item.id}
                                                className="list-group-item"
                                                onClick={() => handleBookSelect(item)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <h3>{item.volumeInfo.title}</h3>
                                                {item.volumeInfo.authors && (
                                                    <p>Author(s): {item.volumeInfo.authors.join(', ')}</p>
                                                )}
                                                {item.volumeInfo.publishedDate && (
                                                    <p>Published Date: {item.volumeInfo.publishedDate}</p>
                                                )}
                                                {item.volumeInfo.imageLinks && (
                                                    <img
                                                        src={item.volumeInfo.imageLinks.thumbnail}
                                                        alt={item.volumeInfo.title}
                                                        className="img-thumbnail"
                                                    />
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <h2>{selectedBook.volumeInfo.title}</h2>
                            {selectedBook.volumeInfo.authors && (
                                <p>Author(s): {selectedBook.volumeInfo.authors.join(', ')}</p>
                            )}
                            {selectedBook.volumeInfo.publishedDate && (
                                <p>Published Date: {selectedBook.volumeInfo.publishedDate}</p>
                            )}
                            {selectedBook.volumeInfo.description && (
                                <p>{selectedBook.volumeInfo.description}</p>
                            )}
                            {selectedBook.volumeInfo.imageLinks && (
                                <img
                                    src={selectedBook.volumeInfo.imageLinks.thumbnail}
                                    alt={selectedBook.volumeInfo.title}
                                    className="img-thumbnail"
                                />
                            )}
                            <ReviewForm book_id={book_id} book={selectedBook} user_id={auth.currentUser.uid}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Addreview;