import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeft, XLg } from 'react-bootstrap-icons';
// import ListElement from './ListElement';

function ListDetails () {
    const location = useLocation();
    const data = location.state;
    const navigate = useNavigate();

    return(
    <div className="book-details container">
        <div className="position-relative mt-3">
            <h2 className='ml-5 pl-5 mb-2'>&nbsp;</h2>
            <button className='position-absolute top-0 start-0' onClick={() => navigate(-1)}>
                <ArrowLeft color='black' size={35}/>
            </button>
            <button className='position-absolute top-0 end-0' onClick={() => navigate(-1)}>
                <XLg color='black' size={35}/>
            </button>
        </div>   
        <hr/>
        <h4>{data.name}</h4>
        <p>{data.description}</p>
        {/* <div className='d-flex align-items-start'>
        {data.books.map((book, index) => (
        <img key={index} className='mr-3' style={{ width: 'auto', height: '150px' }} src={book.thumbnail} alt={book.title}/>
        ))}
        </div>     */}
    </div>
    );
};

export default ListDetails;