import React from 'react';
import "./css/pricing.css";
import "./css/product.css"
import LandingNav from '../components/landing/LandingNav';
import LandingHero from '../components/landing/LandingHero';
import LandingAbout from '../components/landing/LandingAbout';
import LandingPricing from '../components/landing/LandingPricing';
import LandingApp from '../components/landing/LandingApp';
import LandingFooter from '../components/landing/LandingFooter';
import LandingFaq from '../components/landing/LandingFaq';

function Landing() {
    return( 
    <div id='scroll' className="container-fluid py-3">
        <LandingNav/>
        <LandingHero/>
        <LandingAbout/>
        <LandingPricing/>
        <LandingApp/>
        <LandingFaq/>
        <LandingFooter/>
    </div>
    );
};

export default Landing;

