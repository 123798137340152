import React, { useEffect, useState } from 'react';
import { 
    //get_query, 
    auth } from '../components/firebase.js';

function BookClub() {
  const [clubs, setClubs] = useState([]);
  const [club, setClubData] = useState({});
  const [uid, setUid] = useState(null);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function fetchClubs() {
      //const club_data = await get_query('users', ['user', '==', uid]).clubs;
      const club_data = ["abc", "def"];
      setClubs(club_data);
    };
    if(clubs.length < 1){
      fetchClubs();
    };
  }, [uid, clubs.length]);

  useEffect(() => {
    async function getClubs() { 
        //const club_data = await get_query('clubs', ['id', 'in', clubs])
        const club_data = [{name: "Harald Töpfer Fanclub", description: "<<insert Aurellis gekreische>>"}]
        setClubData(club_data);     
    };
    if(clubs.length > 0){
        getClubs();
    };
  }, [clubs.length, clubs]);


  return(
  <div className="container">
    <h2>{club.name}</h2>
    <hr/>
    sadfsdf
  </div>
  );
};

export default BookClub;