import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInWithRedirect,
  getRedirectResult,
  onAuthStateChanged,
  browserLocalPersistence,
  setPersistence,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  doc,
  Timestamp
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import ReactObserver from 'react-event-observer';

const firebaseConfig = {
  apiKey: "AIzaSyCSBxUOEoMwocE2WWU8qkyjaua2fz0M-38",
  authDomain: "collibre-85893.firebaseapp.com",
  projectId: "collibre-85893",
  storageBucket: "collibre-85893.appspot.com",
  messagingSenderId: "181107283943",
  appId: "1:181107283943:web:f8b75be8dcfd6ed28ccf42",
  measurementId: "G-XX65QLSKGN"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage();

export function loggedIn() {
    return !!auth.currentUser;
}

const create_user = async (user, authprovider, username) => {
  await addDoc(collection(db, "users"), {
    uid: user.uid,
    name: username,
    authProvider: authprovider,
    email: user.email,
    created: Timestamp.fromDate(new Date()),
    updated: Timestamp.fromDate(new Date()),
    lastLogin: Timestamp.fromDate(new Date()),
    bio: "",
    location: "",
    visibility: true
  });
};

const signInWithGoogle_old = async (stayLoggedIn) => {
  try {
    if(stayLoggedIn){
      await setPersistence(auth, browserLocalPersistence);
    }
    
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      create_user(user, "google", user.displayName);
    } else {
      const docId = docs.docs[0].id;
      const docSnapshot = docs.docs[0];
      const userData = docSnapshot.data();
      const data = {
        ...userData,
        lastLogin: Timestamp.fromDate(new Date()) 
      }
      update('users', docId, data)
    }
    console.log(user.uid)
    return 0;
  } catch (err) {
    console.error(err);
    alert(err.message);
    return 1;
  }
};


const logInWithEmailAndPassword = async (email, password, stayLoggedIn) => {
  try {
    if(stayLoggedIn){
      await setPersistence(auth, browserLocalPersistence);
    }
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password, stayLoggedIn) => {
  try {
    if(stayLoggedIn){
      await setPersistence(auth, browserLocalPersistence);
    }
    const res = await createUserWithEmailAndPassword(auth, email, password);
    console.log(res);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      create_user(user, "local", name);
    } else {
      const docId = docs.docs[0].id;
      const docSnapshot = docs.docs[0];
      const userData = docSnapshot.data();
      const data = {
        ...userData,
        lastLogin: Timestamp.fromDate(new Date()) 
      }
      update('users', docId, data)
    }
    console.log(user.uid)
    return 0;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent! Please check your e-mail inbox.");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const insert = async (col, data) => {
  try {
      console.log(data);
      const docRef = await addDoc(collection(db, col), data);
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
}

const update = async (col, docId, newData) => {
  try {
    const docRef = doc(db, col, docId);
    await updateDoc(docRef, newData);
    console.log("Document updated successfully");
  } catch (e) {
    console.error("Error updating document: ", e);
  }
}

const remove = async (col, docId) => {
  try {
    const docRef = doc(db, col, docId);
    await deleteDoc(docRef);
    console.log("Document deleted successfully");
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
}

const get = async (col, docId) => {
  try {
    const docRef = doc(db, col, docId);
    
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  } catch (e) {
    console.error("Error getting document: ", e);
  }
};

const get_query = async (col, qry) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, col), where(qry[0], qry[1], qry[2])));
    if(querySnapshot.empty){
      return [];
    }
    // querySnapshot.forEach((doc) => {
    //   console.log(doc.id, " => ", doc.data());
    // });
    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log("return", data);
    return data;
  } catch (e) {
    console.error("Error getting documents: ", e);
  }
};

const insert_to_storage = (file, user) => {
  const path = 'profile_pictures/' + user.id + '.' + file.type.slice(file.type.indexOf('/') + 1);
  console.log(file.name, file.type, path);
  const storageRef = ref(storage, path);

  uploadBytes(storageRef, file).then(() => {
    console.log('Uploaded a blob or file!');
    getDownloadURL(ref(storage, path))
    .then((url) => {
      const data = {
        ...user,
        image: url
      }
      update('users', user.id, data)
  })
  .catch((error) => {
    // Handle any errors
  });
  });

};

const delete_from_storage = () => [

];

const getTopReviewedBooks = async () => {
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const reviewsQuerySnapshot = await getDocs(query(collection(db, 'review'), where('created', '>=', oneWeekAgo)));

  const reviewCounts = {};
  reviewsQuerySnapshot.forEach((doc) => {
    const data = doc.data();
    const bookId = data.book;
    if (!reviewCounts[bookId]) {
      reviewCounts[bookId] = 0;
    }
    reviewCounts[bookId] += 1;
  });

  // Convert the counts object to an array of bookIds sorted by review count
  const sortedBooks = Object.entries(reviewCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 10)
    .map(entry => entry[0]);

  return sortedBooks;
};

export {
  app,
  getAuth,
  get, 
  get_query, 
  insert, 
  update, 
  remove, 
  getRedirectResult,
  signInWithRedirect,
  signInWithGoogle_old,
  onAuthStateChanged,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  insert_to_storage,
  delete_from_storage,
  getTopReviewedBooks,
};