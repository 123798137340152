import { useEffect } from 'react';
import { logout } from '../components/firebase.js';
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  
  useEffect(() => {
    logout();
    return navigate("/")
  }, [navigate]);
};

export default Logout;
