import React, { useState } from 'react';
import { insert } from '../components/firebase.js';
import { Timestamp } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import { LockFill, Globe, PeopleFill, Trophy, TrophyFill } from 'react-bootstrap-icons'; // ShareFill
import { useLocation } from 'react-router-dom' 

function Addlist () {
  const navigate = useNavigate();
  const location = useLocation()
  const uid = location.state;

  const [newList, setNewList] = useState({
    user: uid,
    created: Timestamp.fromDate(new Date()),
    updated: Timestamp.fromDate(new Date()),
    name: "",
    description: "",
    max_entries: 20,
    ranked: false,
    visibility: "public",
    entries: []
  });

  const handleSaveList = () => {
    console.log(uid);
    insert("lists", newList);

    let activity = {
        timestamp: Timestamp.fromDate(new Date()),
        user: uid,
        type: "list-create",
        content: {
          name: newList.name
        }
        
    };
    insert("activity", activity)
    navigate("/nav/lists");
  };

  const handleName = (event) => {
    setNewList({
      ...newList,
      name: event.target.value
    });
  };

  const handleDescription = (event) => {
    setNewList({
      ...newList,
      description: event.target.value
    });
  };

  const setVisibility = (visibility) => {
    setNewList({
        ...newList,
        visibility: visibility
      });
  };

  const setRanked = () => {
    setNewList({
        ...newList,
        ranked: !newList.ranked
    });
    console.log(newList.ranked)
  };

  return (
    <div className='container'>
      <div className="form-group">
        <label>
          List title:
          <input type="text" className="form-control" name="review" value={newList.name} onChange={handleName}/>
        </label>
      </div>
      <div className="form-group">
        <label>
          Description:
          <input type="text" className="form-control" name="review" value={newList.description} onChange={handleDescription}/>
        </label>
      </div>
      <p>Visibility</p>
      {newList.visibility === 'public' ? (
        <Globe/>
      ) : ""}
      {newList.visibility === 'friends' ? (
        <PeopleFill/>
      ) : ""}
      {newList.visibility === 'private' ? (
        <LockFill/>
      ) : ""}
      <label for="visibility">This list should be visible to</label><br/>
        <select name='visibility' id='visibility'>
            <option value='public' className="dropdown-item  d-flex align-items-start" onClick={() => setVisibility('public')}>
                Anyone
            </option>
            {/* <option value='share' className="dropdown-item  d-flex align-items-start" onClick={() => setVisibility('share')}>
                <ShareFill/>
                Anyone with share link
            </option> */}
            <option value='friends' className="dropdown-item  d-flex align-items-start" onClick={() => setVisibility('friends')}>
                Your friends
            </option>
            <option value='private' className="dropdown-item  d-flex align-items-start" onClick={() => setVisibility('private')}>
                Just you
            </option>
        </select><br/>
        {newList.ranked ? (<button onClick={() => setRanked}><TrophyFill/></button>) : (<button onClick={() => setRanked}><Trophy/></button>)}<br/>
      <button type="button" className="btn btn-primary mt-2" onClick={handleSaveList}>
        Save
      </button>
    </div>
  );
};

export default Addlist;
