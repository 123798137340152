import React, { useState, useEffect } from 'react';
import { get_query, auth } from '../components/firebase';
import StarRating from '../components/StarRating';

const Activity = () => {
  const [activity, setActivity] = useState([]);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function fetchActivity() {
      const activity_data = await get_query('activity', ['user', '==', uid]); // auth.currentUser.uid]);
      setActivity(activity_data);
      activity_data.sort(function(x, y){return y.timestamp - x.timestamp})
    };
    if(activity.length === 0){
      fetchActivity();
    }
    
  }, [activity, uid]);

  return(
    <div className="user-activity-list container">
      <h2>Your activity</h2>
      {activity.map((data) => (
        <div key={data.id} className='fs-5'>
          {data.type === "review" ? (
            <div className='d-flex align-items-start'>
              <p>You {data.content.reread ? "reread " : "read "}</p>
              {data.content.like ? <p>, liked</p> : ""}&nbsp;
              {data.content.stars > 0 ? <p>and rated </p> : ""}
              &nbsp;
              <p>{data.content.title}</p>
              &nbsp;
              <div className='mt-2'><StarRating size="15" color='dark-gray' rating={data.content.stars}/></div>&nbsp;
              <p>on {new Date(data.timestamp.seconds * 1000).toLocaleDateString('en-US')}</p>
            </div> 
          ) : ""}
          {data.type === "review-update" ? (
            <div className='d-flex align-items-start'>
              <p>You updated your</p>&nbsp;
              <div className='mt-2'><StarRating size="15" color='dark-gray' rating={data.content.stars}/></div>&nbsp;
              <p>review of {data.content.title}</p>&nbsp;
              <p>on {new Date(data.timestamp.seconds * 1000).toLocaleDateString('en-US')}</p>
            </div> 
          ) : ""}
          {data.type === "review-delete" ? (
            <div className='d-flex align-items-start'>
              <p>You deleted your</p>&nbsp;
              <div className='mt-2'><StarRating size="15" color='dark-gray' rating={data.content.stars}/></div>&nbsp;
              <p>review of {data.content.title}</p>&nbsp;
              <p>on {new Date(data.timestamp.seconds * 1000).toLocaleDateString('en-US')}</p>
            </div> 
          ) : ""}
          <hr/>
        </div>
      ))}
    </div>
  );
};

export default Activity;