import React from 'react';
import img1 from "../../assets/img/Designer.jpeg"
import { useNavigate } from 'react-router-dom';

const LandingHero = () => {
    const navigate = useNavigate();

    return (
    <section id="hero" style={{height: "100vh"}}>
        <div className="container col-xxl-8 px-4 py-5">
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
                <img src={img1} className="d-block mx-lg-auto img-fluid rounded" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
            </div>
            <div className="col-lg-6">
                <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Welcome to BookBanter</h1>
                <p className="lead">Track, rate and share your reading experiences!</p>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button type="button" className="btn btn-dark btn-lg px-4 me-md-2" onClick={() => navigate("signup/")}>Sign up!</button>
                <button type="button" className="btn btn-outline-secondary btn-lg px-4" onClick={() => navigate("/login")}>Sign in!</button>
                </div>
            </div>
            </div>
        </div>
    </section>
    );
};

export default LandingHero;