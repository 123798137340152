import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { List, StarFill, Search, Person, JournalCheck, Journal, JournalText, Star, GraphUp, Gear, BoxArrowRight, People } from "react-bootstrap-icons";
import logo from "../logo.png";
import { auth } from './firebase';

function Navbar() {
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState("Home");
    const navigate = useNavigate();

    useEffect(() => {
        auth.onAuthStateChanged(user => {
          if (!user) {
            navigate('/login');
          }
        });
    }, [navigate]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
                setIsOpen(true);
            }
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const linkClick = (target) => {
        setPage(target)
        if(isMobile){
            toggleNavbar();
        }
    }

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };


    return(
    <div className="container-fluid m-0 p-0">
        <div className="row flex-nowrap">
            {isOpen ? (
                <div className="col-auto col-xs-10 col-md-3 col-xl-2 bg-dark h-100">
                <div className="bg-dark d-flex flex-column align-items-center align-items-sm-start px-4 pt-2 text-white min-vh-100 position-absolute position-fixed z-2">
                    <a href="/" className="d-flex align-items-start align-items-center ml-1 me-md-auto text-white text-decoration-none mt-2 mb-4">
                        <img src={logo} alt='collibre logo' style={{ width: '85px', height: '85px'}}/> &nbsp; <span className="fs-4 ml-5 mr-5 d-none d-sm-inline">Book<br/>Banter</span>
                    </a>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Home")}> <StarFill color="white"/> &nbsp; <Link to="/nav/home" >Popular</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Search")}> <Search color="white"/> &nbsp; <Link to="/nav/search">Search</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Book clubs")}> <People color="white"/> &nbsp; <Link to="/nav/clubs">Book clubs</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Profile")}> <Person color="white"/> &nbsp; <Link to="/nav/profile">Profile</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Readlist")}> <JournalCheck color="white"/> &nbsp; <Link to="/nav/readlist">Readlist</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Lists")}> <Journal color="white"/> &nbsp; <Link to="/nav/lists">Lists</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Diary")}> <JournalText color="white"/> &nbsp; <Link to="/nav/diary">Diary</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Reviews")}> <Star color="white"/> &nbsp; <Link to="/nav/reviews">Reviews</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Activities")}> <GraphUp color="white"/> &nbsp; <Link to="/nav/activity">Activity</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent" onClick={() => linkClick("Settings")}> <Gear color="white"/> &nbsp; <Link to="/nav/settings">Settings</Link> </button> </li>
                        <li> <button className="nav-item d-flex align-items-center btn btn-transparent"> <BoxArrowRight color="white"/> &nbsp; <Link to="/nav/signout">Sign out</Link> </button> </li>
                    </ul>
                    <hr/>
                    <span className="d-none d-sm-inline mx-0 my-2">Version 0.0.0</span>
                </div>
            </div>
            ) : (
                <div className="position-absolute h-16 bg-dark ml-0 mb-5 mr-0 d-flex align-items-start z-2">
                    <button className='position-absolute top-0 start-0 ml-4 mt-3 mb-3 z-3' onClick={toggleNavbar}>
                        <List color='white' size={35}/>
                    </button>
                    <p className='text-white fs-2 position-absolute top-0 end-0 mt-2 mr-4'>{page}</p>
                </div>                
            )}
            <div className="col-md-offset-3 col-md-9 col-xl-10 col-xs-2">  
                {isOpen && isMobile ? (
                    <button className='offset-md-3 position-absolute offset-xs-3 h-100 w-100 z-1 opacity-25' onClick={toggleNavbar}>
                    </button>) : ""}
                    <div className="App-intro text-dark z-0 with-auto mt-3">
                        <Outlet/>
                    </div>
            </div>
        </div>
    </div>
)};

export default Navbar;