import React from 'react';
import "./css/imprint.css";
import { useNavigate } from "react-router-dom";
import shelf2 from "../assets/img/shelf2.jpeg"
import { ArrowLeft, XLg } from "react-bootstrap-icons";

const Imprint = () => {
    const navigate = useNavigate();

    return(
        <div className='imprint'>
            <img src={shelf2} alt="background" className="bg-image position-absolute z-0"/>
            <div className="mt-3 z-1">
                <h2 className='ml-5 pl-5 mb-2'>&nbsp;</h2>
                <button className='position-absolute top-0 start-0 btn btn-transparent' onClick={() => navigate("/")}>
                    <ArrowLeft color='black' size={40}/>
                </button>
                <button className='position-absolute top-0 end-0 btn btn-transparent' onClick={() => navigate("/")}>
                    <XLg color='black' size={40}/>
                </button>
            </div> 
            <div className="imprint__container z-1">
                <h1>Site Notice</h1>

                <h2>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
                <p>Tobias St&ouml;ffel<br/></p>
                {/* Emil-Marent-Str. 12<br />
                87439 Kempten</p> */}

                <h2>Contact</h2>
                {/* <p>E-mail: tobi01@stoeffel.eu</p> */}

                <h2>EU dispute resolution</h2>
                <p>The European Commission provides a platform for online dispute resolution (ODR): <br/>
                <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Our e-mail address can be found above in the site notice.</p>

                <h2>Dispute resolution proceedings in front of a consumer arbitration board</h2>
                <p>We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.</p>    
            </div>
        </div>
    );
};

export default Imprint