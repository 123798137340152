import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logInWithEmailAndPassword, signInWithGoogle_old } from "./firebase";
import "./css/Login.css";
import shelf2 from "../assets/img/shelf2.jpeg"
import { ArrowLeft, XLg } from "react-bootstrap-icons";
import logo from "../logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();
  
  const login_google = async (e) => {
    e.preventDefault();
    if(agreed === false){
      alert("You have to agree to the terms and conditions and confirm that you have read the privacy policy");
      return;
    }

    const res = await signInWithGoogle_old(stayLoggedIn);
    if(res === 0){
      navigate('/nav/home');
    }
  };

  const login_email_pw = async (e) => {
    e.preventDefault();
    if(email === ""){alert("Please provide a valid e-mail adress"); return;};
    if(password === ""){alert("Please provide your password"); return;};
    if(agreed === false){
      alert("You have to agree to the terms and conditions and confirm that you have read the privacy policy");
      return;
    }
    const res = await logInWithEmailAndPassword(email, password, stayLoggedIn);
    if(res === 0){
      navigate('/nav/home');
    } else {
      alert("Ups! Something went wrong! Please check if you entered your e-mail adress and your password correctly.");
    }
  };

  return (
    <div className="login">
      <img src={shelf2} alt="background" className="bg-image position-absolute z-0"/>
      <div className="mt-3 z-1">
        <h2 className='ml-5 pl-5 mb-2'>&nbsp;</h2>
        <button className='position-absolute top-0 start-0 btn btn-transparent' onClick={() => navigate("/")}>
            <ArrowLeft color='black' size={40}/>
        </button>
        <button className='position-absolute top-0 end-0 btn btn-transparent' onClick={() => navigate("/")}>
            <XLg color='black' size={40}/>
        </button>
      </div> 
      <div className="login__container z-1">
        <img className="mb-3 mx-auto" src={logo} width="75" height="75" viewBox="0 0 118 94" alt="Logo"/>
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={login_email_pw}
        >
          Login
        </button>
        <button className="login__btn login__google" onClick={login_google}>
          Sign in with Google
        </button>
          <label>
            <input
              className="mx-2"
              type="checkbox"
              checked={stayLoggedIn}
              onChange={(e) => setStayLoggedIn(e.target.checked)}
            />
            Stay logged in
          </label>
          <label>
            <input
              className="mx-2"
              type="checkbox"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
            />
            I agree to the <a className="text-dark" href="/terms&conditions">terms and conditions </a><br/>
            and I accept the <a className="text-dark" href="privacy-policy">privacy policy</a> 
          </label>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/signup">Register</Link> now.
        </div>
      </div>
    </div>
  );
}
export default Login;