import React, { useState, useEffect } from 'react';
import { getTopReviewedBooks, get_query } from '../components/firebase';
import { PlusCircleFill } from "react-bootstrap-icons";
import { Link} from 'react-router-dom';

const Home = () => {
  const [top10ISBNs, setTop10ISBNs] = useState([]);
  const [top10Books, setTop10Books] = useState([]);

  useEffect(() => {
    async function fetchTop10ISBNs() {
      const topbooks = await getTopReviewedBooks()
      setTop10ISBNs(topbooks);
    };
    if(top10ISBNs.length === 0){
      fetchTop10ISBNs();
    }
  }, [top10ISBNs]);

  useEffect(() => {
    async function fetchTop10Books() {
      const book_data = await get_query('books', ['isbn', 'in', top10ISBNs])
      setTop10Books(book_data);
    };

    if(top10ISBNs.length > 0){
      fetchTop10Books();
    };
  }, [top10ISBNs]);

  return (
    <div className="container mt-4">
      <div className="rounded-circle position-fixed hover-shadow" style={{ bottom: '30px', right: '30px' }}>
        <Link to="/nav/reviews/add" ><PlusCircleFill size={65} color="rgb(33,37,41)"/></Link>
      </div>
      <h2 className='mb-3'>Popular this week</h2>
      <hr/>
      <div className='d-flex align-itmes-start'>
        {top10Books.map((book, index) => (
          <div key={index} className='fs-5 mr-2'>
            <Link to="/nav/book/details" state={book} style={{ textDecoration: 'none', color: 'black', marginRight: "10px"}}>
              <img src={book.thumbnail} alt={book.title} style={{ width: 'auto', height: '200px' }}/>
            </Link>
          </div>
        ))}
      </div>
      <hr/>
      <h2 className='mb-3 mt-5'>Popular with friends</h2>
      <hr/>
    </div>
  );
}

export default Home;
