import React from 'react';
// import ListElement from './ListElement';

const List = ({name, description, books}) => (
  <div>
    <h4>{name}</h4>
    <p>{description}</p>
    <div className='d-flex align-items-start'>
    {books.map((book, index) => (
      <img key={index} className='mr-3' style={{ width: 'auto', height: '150px' }} src={book.thumbnail} alt={book.title}/>
    ))}
    </div>    
    <hr/>
  </div>
);

export default List;