import React, { useState } from 'react';
import { Heart, HeartFill, ArrowRepeat, Star, StarFill, Eye, EyeFill } from "react-bootstrap-icons";
import { insert } from '../components/firebase.js';
import { Timestamp } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";

const ReviewForm = ({book_id, book, user_id}) => {
  const navigate = useNavigate();
  const [newReview, setNewReview] = useState({
    user: user_id,
    book: book_id,
    created: Timestamp.fromDate(new Date()),
    updated: Timestamp.fromDate(new Date()),
    like: false,
    reread: false,
    review: '',
    spoilers: false,
    stars: 0,
  });

  const handleSaveReview = () => {
    insert("review", newReview);

    let activity = {
        timestamp: Timestamp.fromDate(new Date()),
        user: user_id,
        type: "review",
        content: {
          reread: newReview.reread,
          like: newReview.like,
          stars: newReview.stars,
          review: newReview.review,
          title: book.volumeInfo.title
        }
        
    };
    insert("activity", activity)
    navigate("/nav/home");
  };

  const handleStarClick = (star) => {
    setNewReview({
      ...newReview,
      stars: star,
    });
  };

  const handleLikeClick = () => {
    setNewReview({
      ...newReview,
      like: !newReview.like,
    });
  };

  const handleSpoilersClick = () => {
    setNewReview({
      ...newReview,
      spoilers: !newReview.spoilers,
    });
  };

  const handleRereadClick = () => {
    setNewReview({
      ...newReview,
      reread: !newReview.reread,
    });
  };

  const handleText = (event) => {
    setNewReview({
      ...newReview,
      review: event.target.value
    });
  }

  return (
    <form>
      <div className="form-group">
        <label>
          Like:
          <button type="button" className="btn btn-like" onClick={handleLikeClick}>
            {newReview.like ? (<HeartFill color='red'/>) : (<Heart/>)}
          </button>
        </label>
      </div>
      <div className="form-group">
        <label>
          Reread:
          <button type="button" className="btn btn-like" onClick={handleRereadClick}>
            {newReview.reread ? (<ArrowRepeat color='red'/>) : (<ArrowRepeat/>)}
          </button>
        </label>
      </div>
      <div className="form-group">
        <label>
          Review:
          <input type="text" className="form-control" name="review" value={newReview.review} onChange={handleText}/>
        </label>
      </div>
      <div className="form-group">
        <label>
          Spoilers:
          <button type="button" className="btn btn-like"  onClick={handleSpoilersClick}>
            {!newReview.spoilers ? (<Eye/>) : (<EyeFill/>)}
          </button>
        </label>
      </div>
      <div className="form-group">
        <label>
          Stars:
          <div className='d-flex align-items-start'>
            {[1, 2, 3, 4, 5].map((star) => {
              if(star <= newReview.stars){
                return(
                  <StarFill
                    key={star}
                    // className={`star ${star <= newReview.stars ? 'star-filled' : ''}`}
                    onClick={() => handleStarClick(star)}
                  />
                )
              }else{
                return(
                  <Star
                    key={star}
                    // className={`star ${star <= newReview.stars ? 'star-filled' : ''}`}
                    onClick={() => handleStarClick(star)}
                  />
                )
              }
          })}
          </div>
        </label>
      </div>
      <button type="button" className="btn btn-primary" onClick={handleSaveReview}>
        Save review
      </button>
    </form>
  );
};

export default ReviewForm;
