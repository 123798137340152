import "./App.css";
import { React } from 'react';
import { Route, Routes } from "react-router-dom";
import Register from "./components/Register";
import Reset from "./components/Reset";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Landing from "./pages/Landing";
import Settings from "./pages/Settings";
import Search from "./pages/Search";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Readlist from "./pages/Readlist";
import Lists from "./pages/Lists";
import ListDetails from "./pages/ListDetails";
import Diary from "./pages/Diary";
import Reviews from "./pages/Reviews";
import Activity from "./pages/Activity";
import Addreview from "./pages/Addreview";
import ReviewDetails from "./pages/ReviewDetails";
import BookDetails from "./pages/BookDetails";
import Addlist from "./pages/Addlist";
import ProfileView from "./pages/ProfileView";
import BookClubs from "./pages/BookClubs";
import BookClub from "./pages/BookClub";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

function App() {
  return (
    <div className="app">
        <Routes>
        <Route exact path="" element={<Landing/>} />
          <Route exact path="/" element={<Landing/>} />
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/signup" element={<Register/>}/>
          <Route exact path="/reset" element={<Reset/>}/>
          <Route exact path="/imprint" element={<Imprint/>}/>
          <Route exact path="/privacy-policy" element={<Privacy/>}/>
          <Route exact path="/terms&conditions" element={<Terms/>}/>
          <Route exact path="/nav" element={<Navbar/>}>
                <Route exact path="/nav/home" element={<Home/>}/>
                <Route exact path="/nav/search" element={<Search/>}/>
                <Route exact path="/nav/clubs" element={<BookClubs/>}/>
                <Route exact path="/nav/club" element={<BookClub/>}/>
                <Route exact path="/nav/profile" element={<Profile/>}/>
                <Route exact path="/nav/readlist" element={<Readlist/>}/>
                <Route exact path="/nav/lists" element={<Lists/>}/>
                <Route exact path="/nav/list/details" element={<ListDetails/>}/>
                <Route exact path="/nav/diary" element={<Diary/>}/>
                <Route exact path="/nav/reviews" element={<Reviews/>}/>
                <Route exact path="/nav/activity" element={<Activity/>}/>
                <Route exact path="/nav/settings" element={<Settings/>}/>
                <Route exact path="/nav/signout" element={<Logout/>}/>
                <Route exact path="/nav/reviews/add" element={<Addreview/>}/>
                <Route exact path="/nav/review/details" element={<ReviewDetails/>}/>
                <Route exact path="/nav/book/details" element={<BookDetails/>}/>
                <Route exact path="/nav/list/add" element={<Addlist/>}/>
                <Route exact path="/nav/profileview" element={<ProfileView/>}/>
          </Route>
        </Routes>
    </div>
  );
}
export default App;