import React from "react";
import { ArrowLeft, XLg } from "react-bootstrap-icons";
import { useNavigate } from 'react-router-dom'

const GoBack = () => {
    const navigate = useNavigate();

    return (
    <div className="position-relative mt-3">
    <h2 className='ml-5 pl-5 mb-2'>&nbsp;</h2>
        <button className='position-absolute top-0 start-0 btn btn-transparent' onClick={() => navigate(-1)}>
            <ArrowLeft color='black' size={35}/>
        </button>
        <button className='position-absolute top-0 end-0 btn btn-transparent' onClick={() => navigate(-1)}>
            <XLg color='black' size={35}/>
        </button>
    </div>
    );
};

export default GoBack;