import React, { useEffect, useState } from 'react';
import { get_query, auth } from '../components/firebase.js';
import List from '../components/List.js';
import { PlusCircleFill } from "react-bootstrap-icons";
import { Link } from 'react-router-dom';

function Lists() {
  const [lists, setLists] = useState([]);
  const [books, setBooks] = useState([]);
  const [isbn, setISBN] = useState([]);
  const [data, setData] = useState([]);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function fetchLists() {
      const list_data = await get_query('lists', ['user', '==', uid]);
      setLists(list_data);
    };
    if(lists.length < 1){
      fetchLists();
    };
  }, [lists, uid]);

  useEffect(() => {
    if(lists.length > 0){
      let arr = [];
      lists.forEach((list) => {
        list.entries.forEach((entry) => {
          if(!arr.includes(entry.book)){arr.push(entry.book)}
        })
      });
      setISBN(arr);
    };
  }, [lists]);

  useEffect(() => {
    async function fetchBooks() {
      const book_data = await get_query('books', ['isbn', 'in', isbn])
      setBooks(book_data);
    };

    if(isbn.length > 0){
      fetchBooks();
    };
  }, [isbn]);

  useEffect(() => {
    if((books.length > 0) && (lists.length > 0)){
      const res = [];
      lists.forEach((list) => {
        const res2 = [];
        books.forEach((book) => {
          list.entries.forEach((entry) => {
            if(entry.book === book.isbn){
              res2.push(book);    
            };
          })
        });
        res.push({
          	...list,
            books: res2
        });
      });
      res.sort(function(x, y){return y.updated - x.updated});
      setData(res);
    };
  }, [books, lists]);

  return(
  <div className="container">
    <h2>Lists</h2>
    <hr/>
    {data.map((list) => (
      <div key={list.id}>
        <List name={list.name} description={list.description} books={list.books}/>
      </div>
    ))}
    {uid !== null ? (
      <div className="rounded-circle position-fixed hover-shadow" style={{ bottom: '30px', right: '30px' }}>
        <Link to="/nav/list/add" state={uid}><PlusCircleFill size={65} color="rgb(33,37,41)"/></Link>
      </div>
    ) : ""}
  </div>
  );
};

export default Lists;