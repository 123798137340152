import React from 'react';
import "./css/terms.css";
import { useNavigate } from "react-router-dom";
import shelf2 from "../assets/img/shelf2.jpeg"
import { ArrowLeft, XLg } from "react-bootstrap-icons";

const Terms = () => {
    const navigate = useNavigate();

    return(
        <div className='terms'>
            <img src={shelf2} alt="background" className="bg-image position-absolute z-0"/>
            <div className="mt-3 z-1">
                <h2 className='ml-5 pl-5 mb-2'>&nbsp;</h2>
                <button className='position-absolute top-0 start-0 btn btn-transparent' onClick={() => navigate("/")}>
                    <ArrowLeft color='black' size={40}/>
                </button>
                <button className='position-absolute top-0 end-0 btn btn-transparent' onClick={() => navigate("/")}>
                    <XLg color='black' size={40}/>
                </button>
            </div> 
            <div className="terms__container z-1">
                <h1>Terms and conditions</h1>
                <p>This website and it's services are free. You are not entitled to anything. You have no right to do anything that might harm the platform!</p>
            </div>
        </div>
    );
};

export default Terms