import React, { useState } from 'react';
import { update, insert, remove } from '../components/firebase.js';
import { useLocation, useNavigate  } from 'react-router-dom'
import { Timestamp } from 'firebase/firestore';
import { Star, StarFill, Heart, HeartFill, ArrowRepeat, Eye, EyeFill, ArrowLeft, CheckLg, Trash } from "react-bootstrap-icons";

function ReviewDetails() {
    const navigate = useNavigate();
    const location = useLocation()
    const review = location.state

    const [data, setData] = useState(review);

    function saveChanges(){
        if (data !== review){
            const update_data = {
                user: data.user,
                book: data.book,
                created: data.created,
                updated: Timestamp.fromDate(new Date()),
                like: data.like,
                reread: data.reread,
                review: data.review,
                spoilers: data.spoilers,
                stars: data.stars,
            };
            update('review', data.id, update_data)

            let activity = {
                timestamp: Timestamp.fromDate(new Date()),
                user: data.user,
                type: "review-update",
                content: {
                  reread: data.reread,
                  like: data.like,
                  stars: data.stars,
                  review: data.review,
                  title: data.title
                }
                
            };
            insert("activity", activity)
            return navigate("/dashboard/reviews")
        }
    };

    const handleReviewDelete = () => {
        remove('reviews', data.id)
        let activity = {
            timestamp: Timestamp.fromDate(new Date()),
            user: data.user,
            type: "review-delete",
            content: {
              reread: data.reread,
              like: data.like,
              stars: data.stars,
              review: data.review,
              title: data.title
            }
            
        };
        insert("activity", activity)
        return navigate("/dashboard/reviews")
    };

    const handleReviewChange = (event) => {
        setData({
          ...data,
          review: event.target.value
        });
    };

    return (
        <div className="user-reviews-list container">
            <div className="position-relative mt-3">
            <h2 className='ml-5 pl-5 mb-2'>&nbsp;</h2>
                <button className='position-absolute top-0 start-0' onClick={() => navigate(-1)}>
                    <ArrowLeft color='black' size={35}/>
                </button>
                <button className='position-absolute top-0 end-0' onClick={() => saveChanges()}>
                    <CheckLg color='black' size={35}/>
                </button>
            </div>   
            <hr/>
            <h3>{data.title}</h3>
            <p>Release Date: {data.release}</p>
            <p>Logged on: {new Date(data.created.seconds * 1000).toLocaleDateString('en-US')}</p>
            <div className='d-flex align-items-start'>
            {[1, 2, 3, 4, 5].map((star) => {
              if(star <= data.stars){
                return(<StarFill key={star} size={18} color='green' onClick={() => setData({...data, stars: star})}/>)
              }else{
                return(<Star key={star} size={18} color='black' onClick={() => setData({...data, stars: star})}/>)
              }
            })}
            </div>
            <form>
                <div className="form-group">
                    <label>
                    Like:
                    <button type="button" className="btn" onClick={() => setData({...data, like: !data.like})}>
                        {data.like ? (<div><HeartFill color='red'/></div>) : (<div><Heart/></div>)}
                    </button>
                    </label>
                </div>
                <div className="form-group">
                    <label>
                    Reread:
                    <button type="button" className="btn" onClick={() => setData({...data, reread: !data.reread})}>
                        {data.reread ? (<div><ArrowRepeat color='red'/></div>) : (<div><ArrowRepeat/></div>)}
                    </button>
                    </label>
                </div>
                <div className="form-group">
                    <label>
                    Spoilers:
                    <button type="button" className="btn"  onClick={() => setData({...data, spoilers: !data.spoilers})}>
                        {!data.spoilers ? (<div><Eye/></div>) : (<div><EyeFill/></div>)}
                    </button>
                    </label>
                </div>
            </form>
            <div className='d-flex align-items-start mt-2'>
            <img className='mr-3' src={data.thumbnail} alt={data.title} />
            <div className="form-group">
                <label>
                Review:
                <input type="text" className="form-control" name="review" value={data.review} onChange={handleReviewChange}/>
                </label>
            </div>
            </div>
            <hr/>  
            <button type="submit" className="btn btn-primary mr-2" onClick={() => saveChanges()}>
                Save Changes
            </button>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => navigate(-1)}>
                Discard Changes
            </button>
            <button type="button" className="btn btn-danger" onClick={() => handleReviewDelete}>
                <Trash className='mt-1 mb-1'></Trash>
            </button>
        </div>
    );
};

export default ReviewDetails;
