import React, { useState, useEffect} from 'react';
import { get_query, auth } from '../components/firebase';
// import List from '../components/List';

function Profile() {
  // const [input, setInput] = useState("");
  const [user, setUser] = useState([]);
  const [uid, setUid] = useState(null);

  // useEffect(() => {
  //   setInput(user_id);
  //   console.log("read input");
  // }, [user_id]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      // console.log("uid getter");
      // if (user_id === "self"){
        if (user) {
          setUid(user.uid);
        } else {
          setUid(null);
        // }
      // } else {
        // setUid(input);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function fetchUser() {
      console.log("uid", uid);
      const user_data = await get_query('users', ['uid', '==', uid]);
      setUser(user_data); 
      console.log("user: ", user_data)
    };

    if(user.length < 1){
      fetchUser();
    };
  }, [user, uid]);

    return (
      <div className="container">
        <h2 className='mb-5'>Your Profile</h2>
        <hr/>
        {user.map((data) => (
          <div key={data.id}>
            <img style={{ width: '150px', height: '150px', borderRadius: '50%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} src={data.image} alt='profile'/><br/>
            <p>Name: {data.name}</p>
            <p>Location: {data.location}</p>
            <p>Bio: {data.bio}</p>
            <p>Joined on: {new Date(data.created.seconds * 1000).toLocaleDateString('en-US')}</p>
          </div>
        ))}
      </div>
    );
  };

export default Profile;