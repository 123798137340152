import React, { useState } from 'react';
import { get_query } from '../components/firebase';
import { Link } from 'react-router-dom';

function Search() {
    const [activeView, setActiveView] = useState('book');
    const [query, setQuery] = useState('');
    const [books, setBooks] = useState([]);
    const [users, setUsers] = useState([]);
  
    const handleBookSearch = async () => {
        try {
            const response = await fetch(`https://www.googleapis.com/books/v1/volumes?q=${query}&key=AIzaSyDfpTRzvu1HfssY-yesH8HclvT0PRi0r44`);
            const data = await response.json();
            setBooks(data.items);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleUserSearch = async () => {
        try {
          const data = await get_query('users', ['name', '==', query]);
          setUsers(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const handleListSearch = async () => {
        try {
          const data = await get_query('lists', ['name', '==', query]);
          setUsers(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
  
    const handleChange = (event) => {
      setQuery(event.target.value);
    };
  
    const handleSubmit = (event) => {
        event.preventDefault();
        if (activeView === "book"){
            handleBookSearch();
        }else if (activeView === "user"){
            handleUserSearch();
        }else if (activeView === "list"){
            handleListSearch();
        };
    };

    return (
        <div className="container">
            <h2 className='mb-5'>Search</h2>
            <hr />
            <nav className="nav">
                <div className={`col-lg-4 d-flex align-items-center justify-content-center pb-2 ${activeView === "book" ? " border-4 border-black border-top-0 border-end-0 border-start-0" : ""}`}>
                    <button className={'w-100'} onClick={() => setActiveView('book')}>
                        Book
                    </button>
                </div>
                <div className={`col-lg-4 d-flex align-items-center justify-content-center pb-2 ${activeView === "user" ? " border-4 border-black border-top-0 border-end-0 border-start-0" : ""}`}>
                    <button className={'w-100'} onClick={() => setActiveView('user')}>
                        User
                    </button>
                </div>
                <div className={`col-lg-4 d-flex align-items-center justify-content-center pb-2 ${activeView === "list" ? " border-4 border-black border-top-0 border-end-0 border-start-0" : ""}`}>
                    <button className={'w-100'} onClick={() => setActiveView('list')}>
                        List
                    </button>
                </div>
            </nav>
            <div style={{ marginTop: '40px' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={"Search for "+activeView+"..."}
                                value={query}
                                onChange={handleChange}
                            />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="submit">
                                    Search
                                </button>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
                {activeView === 'book' && (
                <div>
                    {books.length > 0 ? (
                        <div>
                            <h2>Search Results</h2>
                            <ul className="list-group">
                                {books.map((item) => (
                                <li key={item.id} className="list-group-item">
                                    <h3>{item.volumeInfo.title}</h3>
                                    {item.volumeInfo.authors && (
                                    <p>Author(s): {item.volumeInfo.authors.join(', ')}</p>
                                    )}
                                    {item.volumeInfo.description && (
                                    <p>{item.volumeInfo.description}</p>
                                    )}
                                    {item.volumeInfo.imageLinks && (
                                    <img
                                        src={item.volumeInfo.imageLinks.thumbnail}
                                        alt={item.volumeInfo.title}
                                        className="img-thumbnail"
                                    />
                                    )}
                                </li>
                                ))}
                            </ul>
                        </div>
                    ) : <p className='mt-5 d-flex justify-content-center'>No book with this name found!</p>}
                </div>
                )}
                {activeView === 'user' && (
                <div>
                {users.length > 0 ? (
                    <div>
                        <h2>Search Results</h2>
                        <ul className="list-group">
                            <hr/>
                            {users.map((item) => (
                            <li key={item.id}>
                                <Link to="/nav/profileview" state={item} style={{textDecoration: 'none', color: 'black'}}>
                                <div className='d-flex align-items-start align-items-center'>
                                <img style={{ width: '75px', height: '75px', borderRadius: '50%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} src={item.image} alt='profile'/>
                                <p className='ml-5 fs-4 mt-3'>{item.name}</p>
                                </div>
                                </Link>
                                <hr/>
                            </li>
                            ))}
                        </ul>
                    </div>
                ) : <p className='mt-5 d-flex justify-content-center'>No user with that name found!</p>}
                </div>
                )}
                {activeView === 'list' && (
                <div>
                {users.length > 0 ? (
                    <div>
                        <h2>Search Results</h2>
                        <hr/>
                        <ul className='pl-0'>
                            {users.map((item) => (
                            <li key={item.id}>
                                <Link to="/nav/list/details" state={item} style={{textDecoration: 'none', color: 'black'}}>
                                <div>
                                    <p className='fs-4'>{item.name}</p>
                                    <p className='fs-5'>{item.description}</p>
                                </div>
                                </Link>
                                <hr/>
                            </li>
                            ))}
                        </ul>
                    </div>
                ) : <p className='mt-5 d-flex justify-content-center'>No list with that name found!</p>}
                </div>
                )}
            </div>
        </div>
    );
};

export default Search;
