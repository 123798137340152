import React, { useEffect, useState } from 'react';
import { get_query, auth } from '../components/firebase.js';
import StarRating from '../components/StarRating.js';
import { Link } from 'react-router-dom';

function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [books, setBooks] = useState([]);
  const [isbn, setISBN] = useState([]);
  const [data, setData] = useState([]);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function fetchReviews() {
      // console.log("Debug 2: ", user_id, isbn, reviews, books);
      const review_data = await get_query('review', ['user', '==', uid]);
      setReviews(review_data);
    };
    if(reviews.length < 1){
      fetchReviews();
    };
  }, [reviews, uid]);

  useEffect(() => {
    if(reviews.length > 0){
      // console.log("Debug 3: ", user_id, isbn, reviews, books);
      let arr = [];
      reviews.forEach((review) => {if(!arr.includes(review.book)){arr.push(review.book)}});
      setISBN(arr);
    };
  }, [reviews]);

  useEffect(() => {
    async function fetchBooks() {
      // console.log("Debug 4: ", user_id, isbn, reviews, books);
      const book_data = await get_query('books', ['isbn', 'in', isbn])
      setBooks(book_data);
    };

    if(isbn.length > 0){
      fetchBooks();
    };
  }, [isbn]);

  useEffect(() => {
    if((books.length > 0) && (reviews.length > 0)){
      // console.log("Debug 5: ", user_id, isbn, reviews, books);
      const res = [];
      reviews.forEach((review) => {
        books.forEach((book) => {
          if(review.book === book.isbn){
              res.push({
                  ...book,
                  ...review
              });    
          };
        });
      });
      res.sort(function(x, y){return y.updated - x.updated});
      setData(res);
    };
  }, [books, reviews]);

  return (
    <div className="user-reviews-list container text-decoration-none">
      <h2>Your Reviews</h2>
      <hr/>
      {data.map((review, index) => (
        <Link to="/dashboard/review/details" state={review} style={{ textDecoration: 'none', color: 'black' }}>
          <div key={index}>
            <h3>{review.title}</h3>
            <p>Release Date: {review.release}</p>
            <p className='mb-0'>Logged on: {new Date(review.created.seconds * 1000).toLocaleDateString('en-US')}</p>
            {review.created.seconds !== review.updated.seconds ? (<p>Updated on: {new Date(review.updated.seconds * 1000).toLocaleDateString('en-US')}</p>) : ""}
            <div className='mt-3'><StarRating rating={review.stars} /></div>
            <div className='d-flex align-items-start mt-2'>
              <Link to="/dashboard/book/details" state={review} style={{ textDecoration: 'none', color: 'black' }}>
              <img className='mr-3' src={review.thumbnail} alt={review.title} />
              </Link>
              <p>{review.review}</p>
            </div>
            <hr/>  
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Reviews;
