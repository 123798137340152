import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordReset } from "./firebase";
import "./css/Reset.css";
import shelf2 from "../assets/img/shelf2.jpeg"
import { ArrowLeft, XLg } from "react-bootstrap-icons";
import logo from "../logo.png";

function Reset() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handle_submit = () => {
    if(email === ""){alert("Please provide a valid e-mail adress!"); return;};
    sendPasswordReset(email)
  };

  return (
    <div className="reset">
      <img src={shelf2} alt="background" className="bg-image position-absolute z-0"/>
      <div className="mt-3 z-1">
        <h2 className='ml-5 pl-5 mb-2'>&nbsp;</h2>
        <button className='position-absolute top-0 start-0 btn btn-transparent' onClick={() => navigate("/")}>
            <ArrowLeft color='black' size={40}/>
        </button>
        <button className='position-absolute top-0 end-0 btn btn-transparent' onClick={() => navigate("/")}>
            <XLg color='black' size={40}/>
        </button>
      </div> 
      <div className="reset__container z-1">
        <img className="mb-3 mx-auto" src={logo} width="75" height="75" viewBox="0 0 118 94" alt="Logo"/>
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <button
          className="reset__btn"
          onClick={handle_submit}
        >
          Send password reset email
        </button>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  );
}
export default Reset;