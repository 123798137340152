import React, { useState, useEffect} from 'react';
import { auth } from '../components/firebase';

function ProfileView() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);


    return (
      <div className="container">
        {user.map((data) => (
          <div key={data.id}>
          <h2 className='mb-5'>{data.name}'s Profile</h2>
          <hr/>
            <img style={{ width: '150px', height: '150px', borderRadius: '50%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} src={data.image} alt='profile'/><br/>
            <p>Name: {data.name}</p>
            <p>Location: {data.location}</p>
            <p>Bio: {data.bio}</p>
            <p>Joined on: {new Date(data.created.seconds * 1000).toLocaleDateString('en-US')}</p>
          </div>
        ))}
      </div>
    );
  };

export default ProfileView;