import { StarFill, Star } from 'react-bootstrap-icons';

const StarRating = ({ rating, size=18, color="green" }) => {
  const filledStars = Math.round(rating);
  const emptyStars = 5 - filledStars;

  return (
    <div className="d-flex align-items-center mb-0">
      {[...Array(filledStars)].map((_, index) => (
        <StarFill size={size} color={color} key={index} className="star-filled" />
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <Star size={size} key={index} className="star-empty" />
      ))}
    </div>
  );
};

export default StarRating;
