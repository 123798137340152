import React, { useState, useEffect} from 'react';
import { get_query, auth } from '../components/firebase';
import { Link } from 'react-router-dom';
import { BoxArrowRight } from "react-bootstrap-icons";
import { insert_to_storage } from '../components/firebase';

function Settings() {
  const [user, setUser] = useState([]);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function fetchUser() {
      const user_data = await get_query('users', ['uid', '==', uid]);
      setUser(user_data);
    };
    if(user.length === 0){
      fetchUser();
    }
    
  }, [user, uid]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform actions to save changes (e.g., send data to server)
    console.log('Form data:', user);
  };

  // Function to handle discarding changes
  const handleDiscardChanges = () => {
    // Reset form data to original settings
    //setFormData(settings);
    console.log("Changes discarded");
  };

  // Function to handle password reset
  const handlePasswordReset = () => {
    // Implement password reset logic (e.g., opening a modal for password reset)
    console.log('Resetting password...');
  };

  // Function to handle account deletion
  const handleDeleteAccount = () => {
    // Implement account deletion logic (e.g., showing confirmation modal)
    console.log('Deleting account...');
  };

  // Function to handle account deletion
  const handleEmailReset = () => {
    // Implement account deletion logic (e.g., showing confirmation modal)
    console.log('Changing e-mail address...');
  };

  const uploadPicture = () => {
    console.log("upload");
    const file = document.getElementById('profilePicInput').files[0];
    insert_to_storage(file, user[0]);
  };

    return (
      <div className="container">
        {user.map((data) => (
        <div key={data.id} onSubmit={handleSubmit}>
        <h2>Your Settings</h2>
        <hr/><h3><b>Profile</b></h3><hr/>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={data.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              className="form-control"
              value={data.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Profile picture</label><br/>
            <input type="file" id="profilePicInput" onChange={uploadPicture} accept="image/*"/>    
          </div>
        <hr/><h3><b>Authentication</b></h3><hr/>
          <p>Auth provider: {data.authProvider}</p>
          <p>Last login: {new Date((data.lastLogin.seconds) * 1000).toLocaleDateString('en-US')}</p>
          <p>E-mail address: {data.email}</p>
          <button className="btn bg-dark">
            <div className='d-flex align-items-start text-decoration-none'>
              <BoxArrowRight color='white' size={20}/> &nbsp; 
              <Link to="/dashboard/signout"  style={{ textDecoration: 'none', color: 'black' }}><p className='text-light'>Sign out</p></Link>
            </div>
          </button>
          <button type="button" className="btn btn-info" onClick={handlePasswordReset}>
            Reset Password
          </button>
          <button type="button" className="btn btn-info" onClick={handleEmailReset}>
            Change e-mail address
          </button>
        <hr/><h3><b>Account</b></h3><hr/>
          <p>Account created: {new Date(data.created.seconds * 1000).toLocaleDateString('en-US')}</p>
          <button type="button" className="btn btn-danger" onClick={handleDeleteAccount}>
            Delete Account
          </button>

          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
          <button type="button" className="btn btn-secondary" onClick={handleDiscardChanges}>
            Discard Changes
          </button>
        </div>
        ))}
      </div>
    );
  };

export default Settings;