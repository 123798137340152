import React, { useEffect, useState } from 'react';
import { get_query, auth } from '../components/firebase.js';
import StarRating from '../components/StarRating.js';
import { Link } from 'react-router-dom';

function Diary() {
  const [reviews, setReviews] = useState([]);
  const [books, setBooks] = useState([]);
  const [isbn, setISBN] = useState([]);
  const [data, setData] = useState([]);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function fetchReviews() {
      // console.log("Debug 2: ", user_id, isbn, reviews, books);
      const review_data = await get_query('review', ['user', '==', uid]);
      setReviews(review_data);
    };
    if(reviews.length === 0){
      fetchReviews();
    };
  }, [reviews, uid]);

  useEffect(() => {
    if(reviews.length > 0){
      // console.log("Debug 3: ", user_id, isbn, reviews, books);
      let arr = [];
      reviews.forEach((review) => {if(!arr.includes(review.book)){arr.push(review.book)}});
      setISBN(arr);
    };
  }, [reviews]);

  useEffect(() => {
    async function fetchBooks() {
      // console.log("Debug 4: ", user_id, isbn, reviews, books);
      const book_data = await get_query('books', ['isbn', 'in', isbn])
      setBooks(book_data);
    };

    if(isbn.length > 0){
      fetchBooks();
    };
  }, [isbn]);

  useEffect(() => {
    if((books.length > 0) && (reviews.length > 0)){
      // console.log("Debug 5: ", user_id, isbn, reviews, books);
      const res = [];
      reviews.forEach((review) => {
        books.forEach((book) => {
          if(review.book === book.isbn){
              res.push({
                  ...review,
                  ...book
              });    
          };
        });
      });
      res.sort(function(x, y){return y.created - x.created});
      setData(res);
    };
  }, [books, reviews]);

  return (
    <div className="user-reviews-list container">
      <h2 className='mb-5'>Your Diary</h2>
      <hr />
      {Object.entries(data.reduce((acc, review) => {
        const date = new Date(review.created.seconds * 1000);
        const monthYear = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(review);
        return acc;
      }, {})).map(([monthYear, reviews]) => (
        <div key={monthYear}>
          <h3><b>{monthYear}</b></h3>
          <hr />
          {reviews.map((review, index) => (
            <div key={index}>
              <div className='d-flex align-items-start'>
                <div className='d-flex align-items-center justify-content-center rounded-3 border border-dark px-3 py-2 mr-4 fs-3' style={{ width: '100px', height: '100px' }}>
                  <div>{new Date(review.created.seconds * 1000).toLocaleDateString('en-US', { day: 'numeric' })}</div>
                </div>
                <Link to="/nav/book/details" state={review} style={{ textDecoration: 'none', color: 'black' }}>
                  <img className='mr-3 ml-3' src={review.thumbnail} alt={review.title} style={{ width: 'auto', height: '100px' }}/>
                </Link>
                <div>
                  <h4>{review.title}</h4>
                  <p>Release Date: {review.release}</p>
                  <StarRating rating={review.stars} />
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
  
};


export default Diary;