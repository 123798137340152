import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { registerWithEmailAndPassword, auth, signInWithGoogle_old } from "./firebase";
import { Link, useNavigate } from "react-router-dom";
import "./css/Register.css";
import shelf2 from "../assets/img/shelf2.jpeg"
import { ArrowLeft, XLg } from "react-bootstrap-icons";
import logo from "../logo.png";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_rep, setPassword_rep] = useState("");
  const [name, setName] = useState("");
  const [user, loading] = useAuthState(auth);
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();

  const login_google = async (e) => {
    e.preventDefault();
    if(agreed === false){
      alert("You have to agree to the terms and conditions and confirm that you have read the privacy policy");
      return;
    }

    const res = await signInWithGoogle_old(stayLoggedIn);
    if(res === 0){
      navigate('/nav/home');
    }
  };

  const register = (e) => {
    e.preventDefault();
    if (!name){alert("Please enter name"); return;};
    if (!email){alert("Please enter an e-mail adress"); return};
    if (!password){alert("Please enter a password"); return;};
    if (!password_rep){alert("Please repeat your password"); return;};
    if(agreed === false){
      alert("You have to agree to the terms and conditions and confirm that you have read the privacy policy");
      return;
    }
    if (password !== password_rep) alert("The repeated password does not equal the given password")
    registerWithEmailAndPassword(name, email, password, stayLoggedIn);
    return navigate('/nav/home');
  };
  useEffect(() => {
    if (loading) return;
  }, [user, loading]);
  return (
    <div className="register">
      <img src={shelf2} alt="background" className="bg-image position-absolute z-0"/>
      <div className="mt-3 z-1">
        <h2 className='ml-5 pl-5 mb-2'>&nbsp;</h2>
        <button className='position-absolute top-0 start-0 btn btn-transparent' onClick={() => navigate("/")}>
            <ArrowLeft color='black' size={40}/>
        </button>
        <button className='position-absolute top-0 end-0 btn btn-transparent' onClick={() => navigate("/")}>
            <XLg color='black' size={40}/>
        </button>
      </div>  
      <div className="register__container z-1">
        <img className="mb-3 mx-auto" src={logo} width="75" height="75" viewBox="0 0 118 94" alt="Logo"/>
        <input
          type="text"
          className="register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="username"
        />
        <input
          type="text"
          className="register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="e-mail address"
        />
        <input
          type="password"
          className="register__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="password"
        />
        <input
          type="password"
          className="register__textBox"
          value={password_rep}
          onChange={(e) => setPassword_rep(e.target.value)}
          placeholder="repeat password"
        />
        <button className="register__btn" onClick={register}>
          Sign up
        </button>
        <button
          className="register__btn register__google"
          onClick={login_google}
        >
          Sign up with Google
        </button>
        <label>
          <input
            className="mx-2"
            type="checkbox"
            checked={stayLoggedIn}
            onChange={(e) => setStayLoggedIn(e.target.checked)}
          />
          Stay logged in
        </label>
        <label>
          <input
            className="mx-2"
            type="checkbox"
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
          />
          I agree to the <a className="text-dark" href="/terms&conditions">terms and conditions </a><br/>
          and I accept the <a className="text-dark" href="privacy-policy">privacy policy</a> 
        </label>
        <div>
          Already have an account? <Link to="/login">Login</Link> now.
        </div>
      </div>
    </div>
  );
}
export default Register;