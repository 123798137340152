import React from 'react';
import { ChevronRight } from 'react-bootstrap-icons';

const landing_app = () => (
    <section id="app" style={{height: "100vh"}}>
        <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
            <div className="col-md-6 p-lg-5 mx-auto my-5">
                <h1 className="display-3 fw-bold">Designed for engineers</h1>
                <h3 className="fw-normal text-muted mb-3">Build anything you want with Aperture</h3>
                <div className="d-flex gap-3 justify-content-center lead fw-normal">
                    <a className="icon-link" href="/">
                    Learn more
                    <svg className="bi"><ChevronRight/></svg>
                    </a>
                    <a className="icon-link" href="/">
                    Buy
                    <svg className="bi"><ChevronRight/></svg>
                    </a>
                </div>
                </div>
                <div className="product-device shadow-sm d-none d-md-block"></div>
            <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
        </div>
    </section>
);

export default landing_app;